module.exports = [{
      plugin: require('/Users/dimitri/Documents/0_JOIN/1_DEV/7_JOIN_STORIES_BLOG/stories-hub/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"minimal-ui","icon":"src/images/fav-icon.png"},
    },{
      plugin: require('/Users/dimitri/Documents/0_JOIN/1_DEV/7_JOIN_STORIES_BLOG/stories-hub/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/dimitri/Documents/0_JOIN/1_DEV/7_JOIN_STORIES_BLOG/stories-hub/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"content":{"padding":"0px","top":0,"left":0,"right":0,"bottom":0},"overlay":{"zIndex":1001}}}},
    },{
      plugin: require('/Users/dimitri/Documents/0_JOIN/1_DEV/7_JOIN_STORIES_BLOG/stories-hub/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
